import React from "react";
import { connect } from "react-redux";
import { Link, browserHistory } from "react-router";
import { reduxForm, Field, reset, change, untouch, formValueSelector } from "redux-form";
import { SubmissionError } from "redux-form";
import SelectField from "../../components/Fields/SelectField";
import _ from "lodash";
import MaskDate from "../../components/Util/MaskDate";
import MaskDecimal from "../../components/Util/MaskDecimal";
import ReactTable from "react-table";
import { toastr } from "react-redux-toastr";

import { FINANCIAL_REPORT_LOAD, FINANCIAL_REPORT_LIST_LOAD, FINANCIAL_REPORT_UNLOAD, FINANCIAL_REPORT_DETAILS_LOAD, FINANCIAL_REPORT_DETAILS_UNLOAD, LOGOUT } from "../../actions/Constants";

import api from "../../api/apiUtil";

import Title from "../../components/Layout/Title";

import jsPDF from "jspdf";
import { filePdf } from "react-icons-kit/icomoon/filePdf";
import SvgIcon from "react-icons-kit";
import { fileExcelO } from "react-icons-kit/fa/fileExcelO";
import XLSX from "xlsx/dist/xlsx.mini.min";
import * as FileSaver from "file-saver";
import DropdownListField from "../../components/Fields/DropdownListField";
import { BeatLoader } from "react-spinners";
import OtherUtils from "../../components/Util/OtherUtils";
import fileDownload from "js-file-download";
import MaskCNPJ from "../../components/Util/MaskCNPJ";

class ChargingReport extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      todosContratos: [],
      pesquisando: false,
    };
  }

  UFS = [
    { label: "BA", value: "BA" },
    { label: "MA", value: "MA" },
    { label: "MS", value: "MS" },
    { label: "PE", value: "PE" },
    { label: "PI", value: "PI" },
    { label: "PR", value: "PR" },
    { label: "RN", value: "RN" },
    { label: "SC", value: "SC" },
  ];

  DEFAULT_COLUMNS = [
    {
      Header: "Numero DETRAN",
      accessor: "numeroDetran",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Data Geração",
      accessor: "row",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
      Cell: (row) => <div>{MaskDate.TO_BR_DATETIME(row.original.dataGeracao)}</div>,
    },
    {
      Header: "Período",
      accessor: "row",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
      Cell: (row) => (
        <div>
          {MaskDate.TO_BR(row.original.periodoInicio)} - {MaskDate.TO_BR(row.original.periodoFim)}
        </div>
      ),
    },
    {
      Header: "Qtd. Registros",
      accessor: "qtdRegistros",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Vencimento",
      accessor: "row",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
      Cell: (row) => <div>{MaskDate.TO_BR(row.original.dataVencimento)}</div>,
    },
    {
      Header: "Valor",
      accessor: "row",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
      Cell: (row) => <div>{MaskDecimal.TO_BRL(row.original.valorTotal)}</div>,
    },
    {
      Header: "Linha Digitável",
      accessor: "linhaDigitavel",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "",
      accessor: "row",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
      width: 120,
      Cell: (row) => (
        <div>
          <Link className="btn btn-sm btn-default" onClick={this.showDetails(row.original.id)}>
            <i className="fa fa-info-circle" Title="Visualizar Registros"></i>
          </Link>
          <Link className="btn btn-sm btn-default" onClick={this.printDetails(row)}>
            {" "}
            <SvgIcon size={14} Title="Imprimir Registros PDF" icon={filePdf} />
          </Link>
          <Link className="btn btn-sm btn-default" onClick={this.printDetailsXLS(row)}>
            {" "}
            <SvgIcon size={14} Title="Imprimir Registros XLS" icon={fileExcelO} />
          </Link>
        </div>
      ),
    },
  ];

  DEFAULT_COLUMNS_DETAILS = [
    {
      Header: "Chassi",
      accessor: "chassi",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Gravame",
      accessor: "numeroGravame",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Data Registro",
      accessor: "row",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
      Cell: (row) => <div>{MaskDate.TO_BR(row.original.dataRegistro)}</div>,
    },
  ];

  COLUMNS_PR = [
    {
      Header: "Contrato",
      accessor: "numeroContrato",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Data Registro",
      accessor: "row",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
      Cell: (row) => <div>{MaskDate.TO_BR(row.original.dataRegistro)}</div>,
    },
    {
      Header: "Data Contrato",
      accessor: "row",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
      Cell: (row) => <div>{MaskDate.TO_BR(row.original.dataContrato)}</div>,
    },
    {
      Header: "Chassi",
      accessor: "chassi",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Num. Restrição",
      accessor: "numeroRestricao",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
  ];

  COLUMNS_RN = [
    {
      Header: "Número Registro",
      accessor: "numeroRegistroContrato",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Nome Devedor",
      accessor: "devedorNome",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "CPF/CNPJ Devedor",
      accessor: "devedorCpfCnpj",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Chassi",
      accessor: "chassi",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
  ];

  COLUMNS_MS = [
    {
      Header: "Chassi",
      accessor: "chassi",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Número do Contrato",
      accessor: "numeroContrato",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "CPF/CNPJ Devedor",
      accessor: "cnpjCpfFinanciado",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
  ];

  COLUMNS_PI = [
    {
      Header: "Contrato",
      accessor: "numeroContrato",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "CPF/CNPJ Devedor",
      accessor: "cpfCnpjDevedor",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Chassi",
      accessor: "chassi",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Num. Restrição",
      accessor: "numeroGravame",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
  ];

  COLUMNS_MA = [
    {
      Header: "Contrato",
      accessor: "numContrato",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Data Registro",
      accessor: "dataRegistro",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Chassi",
      accessor: "chassi",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "CPF/CNPJ Proprietário",
      accessor: "cpfCnpjProprietario",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
    {
      Header: "Nome Proprietário",
      accessor: "nomeProprietario",
      className: "align-middle text-center",
      headerClassName: "font-extra-bold",
    },
  ];

  componentWillMount() {
    Promise.resolve(api.Financial.filtroPorUf()).then((all) => {
      this.props.onLoad(all);
    });
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uf !== this.props.uf) {
      this.props.onClearList();
    }
  }

  showDetails = (id) => (ev) => {
    ev.preventDefault();
    Promise.resolve(api.Charging.chargingDetails(id)).then((dt) => {
      this.props.onDetailsLoad([dt]);
    });
  };

  printDetails = (row) => (ev) => {
    ev.preventDefault();
    Promise.resolve(api.Charging.chargingDetails(row.original.id)).then((dt) => {
      this.exportRegisterPDF(row, dt);
    });
  };

  printDetailsXLS = (row) => (ev) => {
    Promise.resolve(api.Charging.chargingDetails(row.original.id)).then((dt) => {
      this.exportRegisterXLS(row, dt);
    });
  };

  async filter(values) {
    this.setState({ pesquisando: true });
    this.showLoadingToast();

    const params = this.buildFilterParams(values);

    if (this.isSpecialUF(values.uf)) {
      await this.handleSpecialUFFilter(params);
    } else {
      await this.handleDefaultFilter(values);
    }
  }

  showLoadingToast() {
    toastr.info("Aguarde", "Realizando consulta...", {
      timeOut: 0,
      component: () => <BeatLoader size={15} margin={2} color={"#58abc3"} loading={true} />,
    });
  }

  buildFilterParams(values) {
    return {
      ano: values.anoReferencia,
      mes: values.mesReferencia,
      numCIC: values.agente.cnpj,
      tipoCIC: "2",
      uf: values.uf,
    };
  }

  isSpecialUF(uf) {
    return ["PR", "MA", "MS", "PI", "RN"].includes(uf);
  }

  async handleSpecialUFFilter(params) {
    try {
      let response = "";
      if (params.uf == "MA") {
        response = await api.Financial.registrosDetranMA({
          mesAno: params.mes + params.ano,
          cnpjFinanceira: params.numCIC,
        });
      } else {
        response = await api.Financial.relatorioBoletosEmitidos(params);
      }
      this.handleSpecialUFResponse(response, params.uf);
    } catch (e) {
      this.handleError(e);
    }
  }

  handleSpecialUFResponse(response, uf) {
    this.setState({ pesquisando: false });
    toastr.removeByType("info");

    const ufHandlers = {
      PR: () => {
        this.props.onListLoad(response);
        if (!response?.length) {
          this.showNoResultsToast();
        }
      },
      MA: () => {
        if (!response) {
          this.showNoResultsToast();
        } else {
          this.props.onListLoad([response]);
        }
      },
      MS: () => {
        if (response?.message) {
          this.showNoResultsToast();
        } else {
          this.props.onListLoad([response]);
        }
      },
      PI: () => {
        if (!response?.body?.registros?.length) {
          this.showNoResultsToast();
        } else {
          this.props.onListLoad([response.body]);
        }
      },
      RN: () => {
        if (!response?.body?.success) {
          toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
        } else {
          this.props.onListLoad(response.body.data);
          if (!response.body.data?.length) {
            this.showNoResultsToast();
          }
        }
      },
    };

    ufHandlers[uf]();
  }

  async handleDefaultFilter(values) {
    try {
      const result = await api.Charging.chargingByPeriod(values.mesReferencia, values.anoReferencia, values.agente.cnpj, values.uf);

      this.props.onListLoad(result);
      this.setState({ pesquisando: false });
      toastr.removeByType("info");

      if (!this.props.chargings?.length) {
        this.showNoResultsToast();
      }
    } catch (e) {
      this.handleError(e);
    }
  }

  handleError(error) {
    this.setState({ pesquisando: false });
    toastr.removeByType("info");

    if (error.message === "Unauthorized" || error.message === "jwt expired") {
      api.Auth.logout();
      this.props.onLogout();
    } else {
      toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
    }
  }

  showNoResultsToast() {
    toastr.info("Aviso", "Não foram encontrados resultados para essa pesquisa.");
  }

  voltar = () => (ev) => {
    ev.preventDefault();
    this.props.onDetailsUnload();
  };

  validateValues(values) {
    const errors = {};

    if (!values.anoReferencia || values.anoReferencia.length === 0 || !values.anoReferencia.trim()) {
      errors.anoReferencia = "Campo obrigatório";
    }
    if (!values.mesReferencia || values.mesReferencia.length === 0 || !values.mesReferencia.trim()) {
      errors.mesReferencia = "Campo obrigatório";
    }
    if (values.agente == undefined || !values.agente.cnpj) {
      errors.agente = "Campo obrigatório";
    }
    if (!values.uf || (values.uf && (values.uf.length === 0 || !values.uf.trim()))) {
      errors.uf = "Campo obrigatório";
    }
    return errors;
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  async gerarBoleto(values) {
    const { anoReferencia, mesReferencia, agente, uf } = values;

    const handlers = {
      PR: async () => {
        const response = await api.Financial.boletoCobranca(anoReferencia, mesReferencia, agente.cnpj);
        this.convertBase(response);
      },
      PI: () => {
        const boleto = this.props.chargings[0].boleto;
        this.convertBase(boleto);
      },
      MS: async () => {
        const params = { numeroDocumento: this.props.chargings[0].numeroDocumento };
        const response = await api.Financial.boletoCobrancaMS(params);
        this.convertBase(response);
      },
    };

    try {
      const handler = handlers[uf];
      if (handler) {
        await handler();
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  convertBase(response) {
    const byteCharacters = atob(response);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const b64toBlob = (response, contentType = "application/pdf", sliceSize = 512) => {
      const byteCharacters = atob(response);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: "application/pdf" });
      return blob;
    };
    const blobT = b64toBlob(response, "application/pdf");
    fileDownload(blobT, "boleto.pdf");
  }

  async detalharPR(values) {
    const { anoReferencia, mesReferencia, agente } = values;

    const periodoParams = {
      inicio: MaskDate.TO_YYYY_MM_DD(new Date(anoReferencia, mesReferencia - 1, 1)),
      fim: MaskDate.TO_YYYY_MM_DD(new Date(anoReferencia, mesReferencia, 0)),
    };

    const requestParams = {
      periodoInicio: periodoParams.inicio,
      periodoFim: periodoParams.fim,
      numCIC: agente.cnpj,
      tipoCIC: "2",
      pagina: "1",
    };

    try {
      const response = await api.Financial.relatorioCobranca(requestParams);
      const { resultado, qtdeTotalRegistros, qtdePorPagina, paginaAtual } = response.body;

      this.props.onDetailsLoad([resultado, Math.ceil(qtdeTotalRegistros / qtdePorPagina), paginaAtual]);
    } catch (error) {
      this.handleError(error);
    }
  }

  async detalharRN(param) {
    var params = {
      uf: this.props.uf,
      identificadorDebito: param,
    };
    try {
      const response = await api.Financial.relatorioCobranca(params);

      if (!response?.body?.success) {
        throw new Error("Falha ao obter detalhes");
      }
      this.props.onDetailsLoad([response.body.data, null, null]);
    } catch (error) {
      this.handleError(error);
    }
  }

  detalharMSMA = () => {
    this.props.onDetailsLoad([this.props.chargings[0].itens, null, null]);
  };

  detalharPI = () => {
    this.props.onDetailsLoad([this.props.chargings[0].registros, null, null]);
  };

  async exportToPDFSpecialUF() {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 50;

    let headers;
    let data;
    if (this.props.uf == "PR") {
      headers = [["Contrato", "Data Registro", "Data Contrato", "Chassi", "Num. Restrição"]];

      if (this.state.todosContratos.length == 0) {
        this.setState({ todosContratos: await this.recuperaContratosDoBoleto() });
      }

      data = this.state.todosContratos.map((row) => [row.numeroContrato, MaskDate.TO_BR(row.dataRegistro), MaskDate.TO_BR(row.dataContrato), row.chassi, row.numeroRestricao]);
    } else if (this.props.uf == "MS") {
      headers = [["Chassi", "Número do Contrato", "CPF/CNPJ Devedor"]];
      data = this.props.details.map((row) => [row.chassi, row.numeroContrato, row.cnpjCpfFinanciado]);
    } else if (this.props.uf == "PI") {
      headers = [["Contrato", "CPF/CNPJ Devedor", "Chassi", "Num. Restrição"]];
      data = this.props.details.map((row) => [row.numeroContrato, row.cpfCnpjDevedor, row.chassi, row.numeroGravame]);
    } else if (this.props.uf == "MA") {
      headers = [["Contrato", "Data Registro", "Chassi", "CPF/CNPJ Proprietário", "Nome Proprietário"]];
      data = this.props.details.map((row) => [row.numContrato, row.dataRegistro, row.chassi, row.cpfCnpjProprietario, row.nomeProprietario]);
    } else {
      headers = [["Número Registro", "Nome Devedor", "CPF/CNPJ Devedor", "Chassi"]];
      data = this.props.details.map((row) => [row.numeroRegistroContrato, row.devedorNome, row.devedorCpfCnpj, row.chassi]);
    }

    var title = `RELATÓRIO DE CONTRATOS - GUIA DETRAN ${this.props.uf}`;

    title = title + `\n\nMês/Ano de Referência: ${this.props.mesReferencia}/${this.props.anoReferencia}`;
    startTableY = startTableY + 15;
    var razaoSocial;
    for (var c of this.props.companies) {
      if (c.cnpj == this.props.agente.cnpj) {
        razaoSocial = c.razaoSocial;
        break;
      }
    }
    title = title + `\nCredor: ${razaoSocial}`;
    startTableY = startTableY + 15;
    title = title + `\nCNPJ: ${MaskCNPJ.TO_CNPJ(this.props.agente.cnpj)}`;

    if (this.props.uf == "RN") {
      startTableY = startTableY + 15;
      title = title + `\nQtd. Registros: ${this.props.details.length}`;
      startTableY = startTableY + 15;
      title = title + `\nVencimento: ${this.props.chargings[0].dataVencimento}`;
      startTableY = startTableY + 15;
      title = title + `\nValor: ${MaskDecimal.TO_BRL(this.props.chargings[0].valorVencimento)}`;
      startTableY = startTableY + 15;
      title = title + `\nLinha Digitável: ${this.props.chargings[0].linhaDigitavel}`;
    } else if (this.props.uf == "MS") {
      startTableY = startTableY + 15;
      title = title + `\nQtd. Registros: ${this.props.details.length}`;
      startTableY = startTableY + 15;
      title = title + `\nVencimento: ${this.props.chargings[0].vencimento}`;
      startTableY = startTableY + 15;
      title = title + `\nValor: R$ ${this.props.chargings[0].totalGeral}`;
      startTableY = startTableY + 15;
      title = title + `\nLinha Digitável: ${this.props.chargings[0].linhaDigitalvel}`;
    }
    startTableY = startTableY + 15;

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      //pageBreak: 'always',
      columnStyles: {
        0: { columnWidth: "wrap" },
        1: {
          columnWidth: "wrap",
        },
        2: {
          columnWidth: "wrap",
        },
        3: {
          columnWidth: "wrap",
        },
      },
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.save(`RelatorioContratosGuiaDetran${this.props.uf}.pdf`);
  }

  exportConfigs = {
    PR: {
      headers: [["Contrato", "Data Registro", "Data Contrato", "Chassi", "Num. Restrição"]],
      mapFunction: (item) => ({
        numeroContrato: item.numeroContrato,
        dataRegistro: MaskDate.TO_BR(item.dataRegistro),
        dataContrato: MaskDate.TO_BR(item.dataContrato),
        chassi: item.chassi,
        numeroRestricao: item.numeroRestricao,
      }),
      fileName: "RelatorioContratosGuiaDetranPR",
      getSource: async function (component) {
        if (!component.state.todosContratos.length) {
          return await component.recuperaContratosDoBoleto();
        }
        return component.state.todosContratos;
      },
    },
    RN: {
      headers: [["Número Registro", "Nome Devedor", "CPF/CNPJ Devedor", "Chassi"]],
      mapFunction: (item) => ({
        numeroRegistroContrato: item.numeroRegistroContrato,
        devedorNome: item.devedorNome,
        devedorCpfCnpj: item.devedorCpfCnpj,
        chassi: item.chassi,
      }),
      fileName: "RelatorioContratosGuiaDetranRN",
      getSource: (component) => component.props.details,
    },
    MA: {
      headers: [["Contrato", "Data Registro", "Chassi", "CPF/CNPJ Proprietário", "Nome Proprietário"]],
      mapFunction: (item) => ({
        numContrato: item.numContrato,
        dataRegistro: item.dataRegistro,
        chassi: item.chassi,
        cpfCnpjProprietario: item.cpfCnpjProprietario,
        nomeProprietario: item.nomeProprietario,
      }),
      fileName: "ContratosRelatorioMA",
      getSource: (component) => component.props.details,
    },
    MS: {
      headers: [["Chassi", "Número do Contrato", "CPF/CNPJ Devedor"]],
      mapFunction: (item) => ({
        chassi: item.chassi,
        numeroContrato: item.numeroContrato,
        cnpjCpfFinanciado: item.cnpjCpfFinanciado,
      }),
      fileName: "RelatorioContratosGuiaDetranMS",
      getSource: (component) => component.props.details,
    },
    PI: {
      headers: [["Contrato", "CPF/CNPJ Devedor", "Chassi", "Num. Restrição"]],
      mapFunction: (item) => ({
        numeroContrato: item.numeroContrato,
        cpfCnpjDevedor: item.cpfCnpjDevedor,
        chassi: item.chassi,
        numeroGravame: item.numeroGravame,
      }),
      fileName: "RelatorioContratosGuiaDetranPI",
      getSource: (component) => component.props.details,
    },
  };

  // Método genérico de exportação
  async exportToCSVSpecialUF(uf) {
    const config = this.exportConfigs[uf];
    if (!config) return;

    const source = await config.getSource(this);
    const dataExcel = source.map(config.mapFunction);

    this.generateExcelFile(config.headers, dataExcel, config.fileName);
  }

  generateExcelFile(headers, data, fileName) {
    const ws = XLSX.utils.aoa_to_sheet(headers);
    XLSX.utils.sheet_add_json(ws, data, {
      skipHeader: true,
      origin: "A2",
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${fileName}.xlsx`);
  }

  render() {
    const { handleSubmit, reset } = this.props;

    if (this.props.details?.length > 0) {
      return (
        <div>
          <Title routes={this.props.routes} params={this.props.params} description="DAEs DETRAN" />{" "}
          <div className="content">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-right">
                  {this.isSpecialUF(this.props.uf) && (
                    <>
                      <Link className="btn btn-default btn-sm" onClick={() => this.exportToPDFSpecialUF()} title="Gerar PDF">
                        {" "}
                        <SvgIcon icon={filePdf} />
                      </Link>
                      <Link className="btn btn-default btn-sm" onClick={() => this.exportToCSVSpecialUF(this.props.uf)} title="Gerar XLS">
                        <SvgIcon icon={fileExcelO} />
                      </Link>
                    </>
                  )}
                  <Link className="btn btn-default btn-sm" onClick={this.voltar()}>
                    <i className="fas fa-arrow-left"></i> Voltar{" "}
                  </Link>
                </div>
                <div className="hpanel">
                  {/* <Link className="btn btn-default" onClick={this.voltar()}>
                    {" "}
                    Voltar
                  </Link> */}
                  <div className="panel-heading">Detalhes da Cobrança</div>
                  <div className="panel-body">
                    <div className="table-responsive">{this.props.uf == "PR" ? this.renderDetailsPR() : this.renderDetails()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Title routes={this.props.routes} params={this.props.params} description="DAEs DETRAN" />{" "}
          <div className="content">
            <div className="row">
              <div className="col-lg-2">
                <Field name="uf" blank={true} label="UF" required data={this.UFS} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
              <div className="col-lg-6">
                <Field
                  name="agente"
                  blank={true}
                  required
                  label="Credor"
                  data={this.props.companies}
                  credor={true}
                  textField="razaoSocial"
                  valueField="cnpj"
                  component={DropdownListField}
                  type="text"
                />
              </div>
              <div className="col-lg-2">
                <Field name="mesReferencia" required label="Mês de Referência" data={OtherUtils.monthsWithOptSel} textField="label" valueField="value" component={SelectField} type="text" />
              </div>
              <div className="col-lg-2">
                <Field name="anoReferencia" required label="Ano de referência" data={OtherUtils.yearsWithOptSel} textField="label" valueField="value" component={SelectField} type="text" />
              </div>

              <div className="col-lg-12">
                <div className="hpanel">
                  <Link
                    className="btn btn-info"
                    disabled={this.state.pesquisando}
                    onClick={this.props.handleSubmit((values) => {
                      return new Promise((resolve, reject) => {
                        var errors;
                        if (this) {
                          errors = this.validateValues(values);
                        }
                        if (errors && !this.isEmpty(errors)) {
                          reject(new SubmissionError(errors));
                        } else {
                          this.filter(values);
                          resolve();
                        }
                      });
                    })}
                  >
                    {" "}
                    {this.state.pesquisando ? "Aguarde..." : "Pesquisar"}
                  </Link>
                  <Link className="btn btn-default" to="/alias/charging-report" onClick={reset}>
                    {" "}
                    Cancelar
                  </Link>
                  <div className="panel-heading">Consultar Cobranças DETRAN</div>
                  <div className="panel-body">
                    <div className="table-responsive">
                      {this.props.chargings?.length > 0 ? (
                        <table cellPadding="1" cellSpacing="1" className="table table-bordered table-striped">
                          {this.renderFinancialReports()}
                        </table>
                      ) : (
                        <small>Nenhum Relatório Encontrado</small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  // Primeiro, vamos criar os mapeamentos de colunas por UF
  getColumnsByUF = (handleSubmit) => ({
    PR: [
      {
        Header: "#",
        accessor: "id",
        className: "text-center align-middle",
      },
      {
        Header: "Nosso Número",
        accessor: "numeroBoletoGRB",
        className: "text-center align-middle",
      },
      {
        Header: "Credor",
        accessor: "agenteFinanceiro",
        className: "text-center align-middle",
      },
      {
        Header: "Qtde. Chassis",
        accessor: "qtdeChassis",
        className: "text-center align-middle",
      },
      {
        Header: "Data Registro",
        accessor: "rowData",
        Cell: (rowData) => MaskDate.TO_BR_DATETIME(rowData.original.dataRegistro),
        className: "text-center align-middle",
      },
      {
        Header: "Data Vencimento",
        accessor: "dataVencimento",
        className: "text-center align-middle",
      },
      {
        Header: "Valor Total",
        accessor: "rowValor",
        Cell: (rowValor) => MaskDecimal.TO_BRL(rowValor.original.valorTotal),
        className: "text-center align-middle",
      },
      {
        Header: "Situação",
        accessor: "situacao",
        className: "text-center align-middle",
      },
      {
        Header: "Data Pagamento",
        accessor: "rowDataPagamento",
        Cell: (rowDataPagamento) => MaskDate.TO_BR_DATETIME(rowDataPagamento.original.dataPagamento),
        className: "text-center align-middle",
      },
      {
        Header: "Valor Pago",
        accessor: "rowValorPago",
        Cell: (rowValorPago) => MaskDecimal.TO_BRL(rowValorPago.original.valorPago),
        className: "text-center align-middle",
      },
      {
        Header: "Ação",
        sortable: false,
        Cell: (row) => (
          <div className="btn-group-vertical">
            <Link onClick={handleSubmit(this.gerarBoleto.bind(this))} target="_blank" className="btn btn-sm btn-default">
              <i className="fas fa-barcode" /> Gerar Boleto
            </Link>
            <Link onClick={handleSubmit(this.detalharPR.bind(this))} target="_blank" className="btn btn-sm btn-default">
              <i className="fas fa-search" /> Detalhar
            </Link>
          </div>
        ),
        className: "text-center align-middle",
      },
    ],
    MA: [
      {
        Header: "Cnpj Financeira",
        accessor: "cnpjFinanceira",
        className: "text-center align-middle",
      },
      {
        Header: "Credor",
        accessor: "nomeFinanceira",
        className: "text-center align-middle",
      },
      {
        Header: "Qtde. Contratos",
        accessor: "quantContratos",
        className: "text-center align-middle",
      },
      {
        Header: "Data Pagamento",
        accessor: "row",
        Cell: (row) => MaskDecimal.TO_BRL(row.original.dataPagamento),
        className: "text-center align-middle",
      },
      {
        Header: "Valor Total",
        accessor: "row",
        Cell: (row) => MaskDecimal.TO_BRL(row.original.valorGuia),
        className: "text-center align-middle",
      },
      {
        Header: "Ação",
        sortable: false,
        Cell: (row) => (
          <div className="btn-group-vertical">
            <Link onClick={() => this.detalharMSMA()} target="_blank" className="btn btn-sm btn-default">
              <i className="fas fa-search" /> Detalhar
            </Link>
          </div>
        ),
        className: "text-center align-middle",
      },
    ],
    MS: [
      {
        Header: "Credor",
        accessor: "nomeProprietario",
        className: "text-center align-middle",
      },
      {
        Header: "Linha Digitável",
        accessor: "linhaDigitalvel",
        className: "text-center align-middle",
      },
      {
        Header: "Data Emissão",
        accessor: "dataEmissao",
        className: "text-center align-middle",
      },
      {
        Header: "Vencimento",
        accessor: "vencimento",
        className: "text-center align-middle",
      },
      {
        Header: "Valor Total",
        accessor: "totalGeral",
        className: "text-center align-middle",
      },
      {
        Header: "Situação",
        accessor: "row",
        Cell: (row) => (row.original.pago ? "Pago" : "Em Aberto"),
        className: "text-center align-middle",
      },
      {
        Header: "Qtde. Chassis",
        accessor: "rowQtdChassis",
        Cell: (rowQtdChassis) => rowQtdChassis.original.itens.length,
        className: "text-center align-middle",
      },
      {
        Header: "Ação",
        sortable: false,
        Cell: (row) => (
          <div className="btn-group-vertical">
            <Link onClick={handleSubmit(this.gerarBoleto.bind(this))} target="_blank" className="btn btn-sm btn-default">
              <i className="fas fa-barcode" /> Gerar Boleto
            </Link>
            <Link onClick={() => this.detalharMSMA()} target="_blank" className="btn btn-sm btn-default">
              <i className="fas fa-search" /> Detalhar
            </Link>
          </div>
        ),
        className: "text-center align-middle",
      },
    ],
    PI: [
      {
        Header: "Credor",
        accessor: "credor",
        className: "text-center align-middle",
      },
      {
        Header: "Linha Digitável",
        accessor: "linhaDigitavel",
        className: "text-center align-middle",
      },
      {
        Header: "Data Vencimento",
        accessor: "rowData",
        Cell: (rowData) => MaskDate.TO_BR(rowData.original.dataVencimento.toString()),
        className: "text-center align-middle",
      },
      {
        Header: "Valor Total",
        accessor: "rowValor",
        Cell: (rowValor) => MaskDecimal.TO_BRL(rowValor.original.valor),
        className: "text-center align-middle",
      },
      {
        Header: "Qtde. Chassis",
        accessor: "quantidadeRegistros",
        className: "text-center align-middle",
      },
      {
        Header: "Ação",
        sortable: false,
        Cell: (row) => (
          <div className="btn-group-vertical">
            <Link onClick={handleSubmit(this.gerarBoleto.bind(this))} target="_blank" className="btn btn-sm btn-default">
              <i className="fas fa-barcode" /> Gerar Boleto
            </Link>
            <Link onClick={() => this.detalharPI()} target="_blank" className="btn btn-sm btn-default">
              <i className="fas fa-search" /> Detalhar
            </Link>
          </div>
        ),
        className: "text-center align-middle",
      },
    ],
    RN: [
      {
        Header: "Nosso Número",
        accessor: "nossoNumero",
        className: "text-center align-middle",
      },
      {
        Header: "Credor",
        accessor: "rowC",
        Cell: (rowC) => `${rowC.original.cgcCpf} - ${rowC.original.nome}`,
        className: "text-center align-middle",
      },
      {
        Header: "Descrição Classe",
        accessor: "descricaoClasse",
        className: "text-center align-middle",
      },
      {
        Header: "Linha Digitável",
        accessor: "linhaDigitavel",
        className: "text-center align-middle",
      },
      {
        Header: "Data Vencimento",
        accessor: "dataVencimento",
        className: "text-center align-middle",
      },
      {
        Header: "Valor Total",
        accessor: "row",
        Cell: (row) => MaskDecimal.TO_BRL(row.original.valorVencimento),
        className: "text-center align-middle",
      },
      {
        Header: "Ação",
        sortable: false,
        Cell: (row) => (
          <div className="btn-group-vertical">
            <Link onClick={() => this.detalharRN(row.original.idRegistroContratoDebito)} target="_blank" className="btn btn-sm btn-default">
              <i className="fas fa-search" /> Detalhar
            </Link>
          </div>
        ),
        className: "text-center align-middle",
      },
    ],
  });

  // Componente unificado de tabela
  renderFinancialReports() {
    const { handleSubmit, uf } = this.props;

    const columns = this.getColumnsByUF(handleSubmit)[uf] || this.DEFAULT_COLUMNS;

    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.chargings || []}
        columns={columns}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        defaultPageSize={50}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }

  exportRegisterPDF(row, dt) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 30;
    const doc = new jsPDF(orientation, unit, size);

    var width = doc.internal.pageSize.getWidth();
    var height = doc.internal.pageSize.getHeight();
    doc.page = 1;

    doc.setFontSize(12);

    var startTableY = 50;

    var title = `Relatório de Cobranças E-Registro`;

    title = title + `\n\nData Geração: ${MaskDate.TO_BR_DATETIME(row.original.dataGeracao)}`;
    startTableY = startTableY + 15;
    title = title + `\nPeríodo: ${MaskDate.TO_BR(row.original.periodoInicio)} - ${MaskDate.TO_BR(row.original.periodoFim)}`;
    startTableY = startTableY + 15;
    title = title + `\nNúmero Detran: ${row.original.numeroDetran}`;
    startTableY = startTableY + 15;
    title = title + `\nQtd. Registros: ${row.original.qtdRegistros}`;
    startTableY = startTableY + 15;
    title = title + `\nVencimento: ${MaskDate.TO_BR(row.original.dataVencimento)}`;
    startTableY = startTableY + 15;
    title = title + `\nValor: ${MaskDecimal.TO_BRL(row.original.valorTotal)}`;
    startTableY = startTableY + 15;
    title = title + `\nLinha Digitável: ${row.original.linhaDigitavel}`;
    startTableY = startTableY + 15;

    const headers = [["CHASSI", "GRAVAME", "DATA REGISTRO"]];

    const data = dt.map((row) => [row.chassi, row.numeroGravame, MaskDate.TO_BR(row.dataRegistro)]);

    var footer = function () {
      //print number bottom right

      doc.setFontSize(7);
      doc.text(width - 50, height - 30, "Página " + doc.page);
      doc.page++;
    };

    let content = {
      startY: startTableY,
      head: headers,
      body: data,
      bodyStyles: { valign: "top" },
      styles: {
        cellPadding: 1.5,
        overflow: "linebreak",
        valign: "middle",
        halign: "center",
        lineColor: [0, 0, 0],
        lineWidth: 0.2,
      },
      //pageBreak: 'always',
      columnStyles: {
        0: { columnWidth: "wrap" },
        1: {
          columnWidth: "wrap",
        },
        2: {
          columnWidth: "wrap",
        },
      },
      didDrawPage: function (data) {
        footer();
      },
    };

    doc.text(title, marginLeft, 30);
    doc.autoTable(content);
    doc.save(`RelatorioCobrancas.pdf`);
  }

  exportRegisterXLS(row, dt) {
    var fileName = "";
    fileName = "RelatorioCobrancas";

    var head = [["Chassi", "Número Gravame", "Data Registro"]];
    var ref = ["chassi", "numeroGravame", "dataRegistro"];
    var data = [];

    data = dt.map((row) => {
      return {
        chassi: row.chassi,
        numeroGravame: row.numeroGravame,
        dataRegistro: MaskDate.TO_BR(row.dataRegistro),
      };
    });

    data.push({ chassi: "" });
    data.push({ chassi: `Data Geração: ${MaskDate.TO_BR_DATETIME(row.original.dataGeracao)}` });
    data.push({ chassi: `Período: ${MaskDate.TO_BR(row.original.periodoInicio)} - ${MaskDate.TO_BR(row.original.periodoFim)}` });
    data.push({ chassi: `Qtd. Registros: ${row.original.qtdRegistros}` });
    data.push({ chassi: `Vencimento: ${MaskDate.TO_BR(row.original.dataVencimento)}` });
    data.push({ chassi: `Valor: ${MaskDecimal.TO_XLS(row.original.valorTotal)}` });
    data.push({ chassi: `Linha Digitável: ${row.original.linhaDigitavel}` });

    var ws = XLSX.utils.aoa_to_sheet(head);
    XLSX.utils.sheet_add_json(ws, data, {
      header: ref,
      skipHeader: true,
      origin: "A2",
    });

    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Resultado");
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${fileName}.xlsx`);
  }

  renderDetailsPR() {
    const { handleSubmit } = this.props;
    var props = this.props;
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.details}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={this.COLUMNS_PR}
        defaultPageSize={50}
        showPageSizeOptions={false}
        minRows={1}
        pages={this.props.pages}
        onPageChange={(pageIndex) => {
          console.log(pageIndex);
        }}
        loading={this.state.loading}
        manual
        onFetchData={(state, instance) => {
          this.setState({ loading: true });
          var anoReferencia = this.props.anoReferencia;
          var mesReferencia = this.props.mesReferencia;
          var agente = this.props.agente.cnpj;

          var firstDay = new Date(anoReferencia, mesReferencia - 1, 1);
          var lastDay = new Date(anoReferencia, mesReferencia, 0);

          var params = {
            periodoInicio: MaskDate.TO_YYYY_MM_DD(firstDay),
            periodoFim: MaskDate.TO_YYYY_MM_DD(lastDay),
            numCIC: this.props.agente.cnpj,
            tipoCIC: "2",
            pagina: state.page + 1,
          };
          Promise.resolve(
            api.Financial.relatorioCobranca(params)
              .then((response) => {
                console.log(response);
                this.props.onDetailsLoad([response.body.resultado, Math.ceil(response.body.qtdeTotalRegistros / response.body.qtdePorPagina), response.body.paginaAtual]);
                this.setState({ loading: false });
              })
              .catch((e) => {
                if (e.message === "Unauthorized" || e.message === "jwt expired") {
                  api.Auth.logout();
                  props.onLogout();
                } else {
                  toastr.error("Erro", "Ocorreu um erro ao realizar a operação!");
                }
              })
          );
        }}
      />
    );
  }

  renderDetails() {
    return (
      <ReactTable
        className="-striped -highlight"
        data={this.props.details}
        previousText="Anterior"
        nextText="Próximo"
        loadingText="Carregando..."
        noDataText="Nenhum registro encontrado"
        pageText="página"
        ofText="de"
        rowsText="linhas"
        columns={
          this.props.uf == "RN"
            ? this.COLUMNS_RN
            : this.props.uf == "MS"
            ? this.COLUMNS_MS
            : this.props.uf == "PI"
            ? this.COLUMNS_PI
            : this.props.uf == "MA"
            ? this.COLUMNS_MA
            : this.DEFAULT_COLUMNS_DETAILS
        }
        defaultPageSize={50}
        showPageSizeOptions={false}
        minRows={1}
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) => dispatch({ type: FINANCIAL_REPORT_LOAD, payload }),
  onListLoad: (payload) => dispatch({ type: FINANCIAL_REPORT_LIST_LOAD, payload }),
  onUnload: () => dispatch({ type: FINANCIAL_REPORT_UNLOAD }),
  onDetailsLoad: (payload) => dispatch({ type: FINANCIAL_REPORT_DETAILS_LOAD, payload }),
  onDetailsUnload: () => dispatch({ type: FINANCIAL_REPORT_DETAILS_UNLOAD }),
  onLogout: () => dispatch({ type: LOGOUT }),
  onClearList: () => dispatch({ type: FINANCIAL_REPORT_LIST_LOAD, payload: [] }),
});

const selector = formValueSelector("ChargingReportForm");
const mapStateToProps = (state) => ({
  ...state.chargingReport,
  currentUser: state.common.currentUser,
  agente: selector(state, "agente"),
  anoReferencia: selector(state, "anoReferencia"),
  mesReferencia: selector(state, "mesReferencia"),
  uf: selector(state, "uf"),
  initialValues: {
    mesReferencia: OtherUtils.getPreviousMonth(),
    anoReferencia: new Date().getFullYear().toString(),
  },
});

const form = reduxForm({
  form: "ChargingReportForm",
});

export default connect(mapStateToProps, mapDispatchToProps)(form(ChargingReport));
